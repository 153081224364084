import {Collapse, Grid, Hidden, IconButton, Typography} from "@mui/material";
import React from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EducationCard from "../../Molecules/EducationCard/EducationCard";
import OtherCard from "../../Molecules/EducationCard/OtherCard";

const Education = [
    {
        place: 'VIA University, Denmark',
        degree: 'Bachelor of Software Engineering',
        date: '2020-2023',
        link: 'https://www.via.dk/en'
    },
    {
        place: 'International Baccalaureate',
        degree: 'Higher Level Math,Physics,English',
        date: '2015-2017',
        link: 'https://www.ibo.org/'
    },
    {
        place: 'Riga State Gymnasyum No.2',
        degree: '12 grades school',
        date: '2015-2017',
        link: 'https://www.rg2.lv/'
    }
]
const OtherCourses = [
    {
        place: 'Understanding TypeScript',
        degree: 'Udemy, Online',
        date: '2020',
        link: 'https://www.udemy.com/course/understanding-typescript/'
    },
    {
        place: 'Practical Data Structures & Algorithms in Java',
        degree: 'Udemy, Online',
        date: '2020',
        link: 'https://www.udemy.com/course/practical-data-structures-algorithms-in-java/'
    },
    {
        place: 'Computer Organization and Architecture',
        degree: 'Udemy, Online',
        date: '2020',
        link: 'https://www.udemy.com/course/computer-organization-and-architecture-j/'
    }
    ]
const OtherCourses2 = [
{
    place: 'The Complete C Language Course',
    degree: 'Udemy, Online',
    date: '2020',
    link: 'https://www.udemy.com/course/c-programming-for-beginners-programming-in-c/'
},
{
    place: 'Programming in C#',
    degree: 'Udemy, Online',
    date: '2020',
    link: 'https://www.udemy.com/course/csharp-ru/'
},
{
    place: 'Full course Android + Java',
    degree: 'Udemy, Online',
    date: '2020',
    link: 'https://www.udemy.com/course/android_sumin/'
},
]
const EducationSection = () => {
    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    return(
        <>
        <Hidden lgDown>
            <Grid container item xs={12} direction={'row'} justifyContent={'space-around'} id={'Education'} my={9}>
                <Grid container item height={"fit-content"} sm={5}>
                    <Grid item xs={12} mb={4}>
                        <Typography  sx={{
                            backgroundImage: 'linear-gradient(45deg, purple, orange)',
                            '-webkit-background-clip': 'text',
                            color: 'transparent',
                            display: 'inline',
                        }}
                                     variant="h2"
                                     fontWeight={'bold'}>Education</Typography>
                    </Grid>
                    {Education.map((item) => (<EducationCard key={item.place + item.degree} education={item} type={'lg'}/>))}
                </Grid>
                <Grid container item sm={5}>
                    <Grid item xs={12} mb={4}>
                        <Typography  sx={{
                            backgroundImage: 'linear-gradient(45deg, purple, orange)',
                            '-webkit-background-clip': 'text',
                            color: 'transparent',
                            display: 'inline',
                        }}
                                     variant="h2"
                                     fontWeight={'bold'}>Other Courses</Typography>
                    </Grid>
                    {OtherCourses.map((item) => (<OtherCard key={item.place} education={item} type={'lg'}/>))}
                    <Grid container item xs={12} direction={'column'} justifyContent={'space-between'} mb={4}>
                        <Collapse in={checked} >
                            {OtherCourses2.map((item) => (<OtherCard key={item.place + item.degree} education={item} type={'lg'}/>))}
                        </Collapse>
                        <Grid item  alignSelf={'center'} mb={4}>
                            <IconButton onClick={handleChange}>{checked?<ExpandLessIcon/>:<ExpandMoreIcon/>}</IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Hidden>


            <Hidden lgUp>
                    <Grid container item xs={12} id={'Education'} >
                        <Grid item xs={12} mb={4}>
                            <Typography  sx={{
                                backgroundImage: 'linear-gradient(45deg, purple, orange)',
                                '-webkit-background-clip': 'text',
                                color: 'transparent',
                                display: 'inline',
                            }}
                                         variant="h4"
                                         fontWeight={'bold'}>Education</Typography>
                        </Grid>
                        {Education.map((item) => (<EducationCard key={item.place + item.degree} education={item} type={'sm'}/>))}
                    </Grid>
                    <Grid container item sm={12}>
                        <Grid item xs={12} mb={4}>
                            <Typography  sx={{
                                backgroundImage: 'linear-gradient(45deg, purple, orange)',
                                '-webkit-background-clip': 'text',
                                color: 'transparent',
                                display: 'inline',
                            }}
                                         variant="h4"
                                         fontWeight={'bold'}>Other Courses</Typography>
                        </Grid>
                           {OtherCourses.map((item) => (<OtherCard key={item.place} education={item} type={'sm'}/>))}
                        <Grid container item xs={12} direction={'column'} justifyContent={'space-between'} mb={4}>
                            <Collapse in={checked} >
                                {OtherCourses2.map((item) => (<OtherCard  key={item.place + item.degree} education={item} type={'sm'}/>))}
                            </Collapse>
                            <Grid item  alignSelf={'center'} mb={4}>
                                <IconButton onClick={handleChange}>{checked?<ExpandLessIcon/>:<ExpandMoreIcon/>}</IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
            </Hidden>
        </>
    )
}
export default EducationSection;