import {AppBar, Hidden, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
interface AppBarProps {
    handleDrawerOpen: ()=>void
}
const AppBarMob = ({ handleDrawerOpen}:AppBarProps) => {
    return(
        <Hidden lgUp>
            {/*<Grid position={'fixed'} container item xs={12} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>*/}
            {/*    <Grid item xs={4}><Typography variant={'h4'}>Romans</Typography>*/}
            {/*        </Grid>*/}
            {/*    <Grid item xs={2}>*/}

            {/*    </Grid>*/}
            {/*</Grid>*/}
            <AppBar variant={'elevation'} color={'secondary'} position="fixed" >
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Romans
                    </Typography>
                    <IconButton  onClick={handleDrawerOpen}>
                        <MenuIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Hidden>
    )
}
export default AppBarMob