import {Avatar, Button, Grid, Hidden, Typography} from "@mui/material";
import Photo from '../../../assets/Roman-147.jpg'
import AvatarPhoto from '../../../assets/Roman-143.jpg'
import React from "react";

interface ProfileSectionProps {
    handleOpenModal:()=>void
}
const ProfileSection = ({handleOpenModal}:ProfileSectionProps) => {
    return(
        <Grid container item  direction={'row'} justifyContent={'center'} id={'Profile'} >
                    <Hidden lgDown>
                        <Grid container item height={'fit-content'} sm={5} >
                        <Grid item xs={12} mb={5}>
                            <Typography

                                variant="h1"
                                fontWeight={'bold'}
                            >
                               <span> <Typography sx={{
                                   backgroundImage: 'linear-gradient(45deg, purple, orange)',
                                   '-webkit-background-clip': 'text',
                                   color: 'transparent',
                                   display: 'inline',
                               }} variant="h1"
                                                  fontWeight={'bold'}>Hello, I am Romans,</Typography></span> Software Engineer  based in Denmark.
                            </Typography>
                    </Grid>
                        <Grid container item xs={12} my={'2rem'} mb={5}>
                            <Grid item xs={12}>
                                <Typography variant={'h5'}>
                                    As a Software Developer, I specialize in TypeScript,React,ExpressJS development applying modern frameworks to build dynamic UIs and ensuring that customers have a consistent and unified experience.</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item sm={12} direction={'row'} justifyContent={'left'}>
                            <Grid item mr={2}>
                                <Button onClick={handleOpenModal} variant={'contained'} sx={{borderRadius:'20px',backgroundImage: 'linear-gradient(45deg, purple, orange)',
                                    display: 'inline',}}   color={'primary'}> <Typography color={"primary"} variant={'h6'}>GET IN TOUCH</Typography></Button>
                            </Grid>
                            <Grid item>
                                <Button href={'#Work Experience'} variant={'outlined'} sx={{borderRadius:'20px'}}  color={"primary"}><Typography color={"primary"} variant={'h6'}>VIEW EXPERIENCE</Typography></Button>
                            </Grid>
                        </Grid>
            </Grid>
                        <Grid container item sm={5} mb={20} direction={'column'} justifyContent={'center'} alignItems={'center'} >
                            <Grid item sm={8} >
                                <img src={Photo} alt={'profile'} width={'525rem'} />
                            </Grid>
                        </Grid>
                    </Hidden>

                    <Hidden smUp>
                        <Grid container item xs={12}  textAlign={'left'}>
                            <Grid item xs={12} mb={'1rem'}>
                                <Avatar sx={{width:'200px', height:'200px', margin:'auto'}} alt={'profile'} src={AvatarPhoto}/>
                            </Grid>
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    backgroundImage: 'linear-gradient(45deg, purple, orange)',
                                    '-webkit-background-clip': 'text',
                                    color: 'transparent',
                                    display: 'inline',
                                }}
                                variant="h4"
                                fontWeight={'bold'}
                            >
                                Hello, I am Romans, Software Engineer  based in Denmark.
                            </Typography>
                        </Grid>
                            <Grid container item xs={12} my={'1rem'}>
                                <Grid item xs={12}>
                                    <Typography variant={'body1'}>
                                        As a Software Developer, I specialize in TypeScript, React, ExpressJS development applying modern frameworks to build dynamic UIs and ensuring that customers have a consistent and unified experience.</Typography>
                                </Grid>
                            </Grid>
                            <Grid container item sm={12} direction={'row'} justifyContent={'center'} mt={'1rem'}>
                                    <Button href={'#Contact'} variant={'contained'} sx={{borderRadius:'20px',backgroundImage: 'linear-gradient(45deg, purple, orange)',
                                        display: 'inline',}}   color={'primary'}> <Typography color={"primary"} variant={'body2'}>GET IN TOUCH</Typography></Button>
                            </Grid>
                        </Grid>
                    </Hidden>
            <Hidden smDown lgUp>
                        <Grid container item xs={12}  textAlign={'center'}>
                            <Grid item xs={12} mb={'1rem'}>
                                <Avatar sx={{width:'200px', height:'200px', margin:'auto'}} alt={'profile'} src={AvatarPhoto}/>
                            </Grid>
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    backgroundImage: 'linear-gradient(45deg, purple, orange)',
                                    '-webkit-background-clip': 'text',
                                    color: 'transparent',
                                    display: 'inline',
                                }}
                                variant="h4"
                                fontWeight={'bold'}
                            >
                                Hello, I am Romans, Software Engineer  based in Denmark.
                            </Typography>
                        </Grid>
                            <Grid container item xs={12} my={'1rem'}>
                                <Grid item xs={12}>
                                    <Typography variant={'body1'}>
                                        As a Software Developer, I specialize in TypeScript, React, ExpressJS development applying modern frameworks to build dynamic UIs and ensuring that customers have a consistent and unified experience.</Typography>
                                </Grid>
                            </Grid>
                            <Grid container item sm={12} direction={'row'} justifyContent={'center'} mt={'1rem'}>
                                <Grid item xs={3}>
                                    <Button onClick={handleOpenModal} variant={'contained'} sx={{borderRadius:'20px',backgroundImage: 'linear-gradient(45deg, purple, orange)',
                                        display: 'inline',}}   color={'primary'}> <Typography color={"primary"} variant={'body2'}>GET IN TOUCH</Typography></Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button href={'#Work Experience'} variant={'outlined'} sx={{borderRadius:'20px'}}  color={"primary"}><Typography color={"primary"} variant={'body2'}>VIEW EXPERIENCE</Typography></Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>

        </Grid>
    )
}
export default ProfileSection;