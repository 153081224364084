import Drawer from "@mui/material/Drawer";
import {
    Button,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    useTheme
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import BoltIcon from "@mui/icons-material/Bolt";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
interface MenuDrawerProps {
    open: boolean
    handleDrawerClose: ()=>void;
    handleTheme: () => void
    themes: 'dark'|'light'
}

const MenuDrawer = ({open,handleDrawerClose,themes,handleTheme}:MenuDrawerProps) => {
    const [activeSection, setActiveSection] = useState(null);
    function scrollToSection(sectionId:string) {
        const element = document.getElementById(sectionId);
        if (element) {
            const yOffset = -100; // Adjust this value as needed for the offset
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            let currentSection = null;

            const sections = ['Profile', 'Work Experience', 'Education', 'Hard Skills', 'Languages', 'Links', 'Contact'];

            sections.forEach(section => {
                const element = document.getElementById(section);
                const rect = element && element.getBoundingClientRect();
                if (rect && rect.top >= 0 && rect.top <= window.innerHeight / 2) { // You can adjust this to better suit your needs
                    currentSection = section;
                }
            });

            setActiveSection(currentSection);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    const theme = useTheme()
    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'space-between',
    }));
    const drawerWidth = 240;
  return(
      <Drawer
          sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                  width: drawerWidth,
              },
          }}
          variant="persistent"
          anchor="right"
          open={open}
      >
          <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
              <Button onClick={handleTheme} variant={'contained'} sx={{borderRadius:'20px'}}  color={"primary"}>{themes}</Button>
          </DrawerHeader>
          <Divider />
          <List>
              {['Profile', 'Work Experience', 'Education', 'Hard Skills', 'Languages', 'Contact'].map((section, index) => (
                  <ListItem onClick={()=>scrollToSection(section)} key={section} sx={activeSection === section ? { borderBottom: `1px solid ${theme.palette.primary.main}` } : {borderBottom: '1px solid rgba(0,0,0,0)'}} disablePadding>
                      <ListItemButton>
                           <ListItemIcon>
                               {activeSection === section ? <BoltIcon />:undefined}
                          </ListItemIcon>
                          <ListItemText primary={section} />
                      </ListItemButton>
                  </ListItem>
              ))}
          </List>
          <Divider />
         <Box sx={{
             display: 'flex',
             alignItems: 'center',
             padding: theme.spacing(0, 5),
             // necessary for content to be below app bar
             ...theme.mixins.toolbar,
             justifyContent: 'flex-start',
         }}>
             <IconButton href={'https://www.linkedin.com/in/romans-jefremovs/'} target={'_blank'}>
                 <LinkedInIcon   fontSize={'large'}/>
             </IconButton>
             <IconButton href={'https://github.com/RomansJefremovs'} target={'_blank'}>
                 <GitHubIcon sx={{marginLeft:'1rem'}}   fontSize={'large'}/>
             </IconButton>
         </Box>
      </Drawer>
  )
}
export default MenuDrawer