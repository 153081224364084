const RotatedLineSpacer = () => {
    return (
        <div className={'rotated-div'}>
            <span className="text-item">Design</span>
            <span className="text-item">Develop</span>
            <span className="text-item">Discover</span>
            <span className="text-item">Design</span>
            <span className="text-item">Develop</span>
            <span className="text-item">Discover</span>
                <span className="text-item">Design</span>
            <span className="text-item">Develop</span>
            <span className="text-item">Discover</span>
            <span className="text-item">Design</span>
            <span className="text-item">Develop</span>
            <span className="text-item">Discover</span>
                <span className="text-item">Design</span>
            <span className="text-item">Develop</span>
            <span className="text-item">Discover</span>
            <span className="text-item">Design</span>
            <span className="text-item">Develop</span>
            <span className="text-item">Design</span>
            <span className="text-item">Develop</span>
            <span className="text-item">Discover</span>
            <span className="text-item">Design</span>
            <span className="text-item">Develop</span>
            <span className="text-item">Discover</span>
            <span className="text-item">Design</span>
            <span className="text-item">Design</span>
            <span className="text-item">Develop</span>
            <span className="text-item">Discover</span>
            <span className="text-item">Design</span>
            <span className="text-item">Develop</span>
            <span className="text-item">Discover</span>
            <span className="text-item">Design</span>
            <span className="text-item">Develop</span>
            <span className="text-item">Discover</span>
            <span className="text-item">Design</span>
            <span className="text-item">Develop</span>
            <span className="text-item">Discover</span>
            <span className="text-item">Design</span>
            <span className="text-item">Develop</span>
            <span className="text-item">Discover</span>
            <span className="text-item">Design</span>
            <span className="text-item">Develop</span>
            <span className="text-item">Design</span>
            <span className="text-item">Develop</span>
            <span className="text-item">Discover</span>
            <span className="text-item">Design</span>
            <span className="text-item">Develop</span>
            <span className="text-item">Discover</span>
            <span className="text-item">Design</span>
        </div>
    )
}
export default RotatedLineSpacer;