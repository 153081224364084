import React, { useState, useEffect } from "react";
import {List, ListItem, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import BoltIcon from '@mui/icons-material/Bolt';
import useScrollTo from "../../../Hooks/useScollToElement/useScrollToElement";
function FloatingSideMenu() {
    const [activeSection, setActiveSection] = useState(null);
    // function scrollToSection(sectionId:string) {
    //     const element = document.getElementById(sectionId);
    //     if (element) {
    //         const yOffset = -100; // Adjust this value as needed for the offset
    //         const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    //         window.scrollTo({ top: y, behavior: 'smooth' });
    //     }
    // }
const scrollTo = useScrollTo()
    useEffect(() => {
        const handleScroll = () => {
            let currentSection = null;

            const sections = ['Profile', 'Work Experience', 'Education', 'Hard Skills', 'Languages', 'Links', 'Contact'];

            sections.forEach(section => {
                const element = document.getElementById(section);
                const rect = element && element.getBoundingClientRect();
                if (rect && rect.top >= 0 && rect.top <= window.innerHeight / 2) { // You can adjust this to better suit your needs
                    currentSection = section;
                }
            });

            setActiveSection(currentSection);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
const theme = useTheme()
    return (
        <Box component={'div'} minWidth={200} maxWidth={250} position={'fixed'} top={250} right={1} maxHeight="calc(100vh - 250px)">
            <List sx={{ position: 'sticky', top: '10px' }}>
                {['Profile', 'Work Experience', 'Education', 'Hard Skills', 'Languages', 'Contact'].map(section => (
                    <Box key={section} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        {activeSection === section ? <BoltIcon /> : undefined}
                        <ListItem onClick={()=>scrollTo(section)} key={section} sx={activeSection === section ? { borderBottom: `1px solid ${theme.palette.primary.main}` } : {marginLeft:3,borderBottom: '1px solid rgba(0,0,0,0)', '&:hover': {
                                borderBottom: `1px solid ${theme.palette.primary.main}`
                            }}}>
                            {section}
                        </ListItem>
                    </Box>
                ))}
            </List>
        </Box>
    );
}

export default FloatingSideMenu;
