import {Grid, Hidden, Typography} from "@mui/material";
import SkillCard from "../../Molecules/SkillCard/SkillCard";
import Box from "@mui/material/Box";

const HardSkills = [
    {image:'https://cdn.worldvectorlogo.com/logos/typescript.svg',title:'TypeScript'},
    {image:'https://cdn.worldvectorlogo.com/logos/logo-javascript.svg',title:'JavaScript'},
    {image:'https://cdn.worldvectorlogo.com/logos/react-2.svg',title:'React'},
    {image:'https://cdn.worldvectorlogo.com/logos/html-1.svg',title:'HTML'},
    {image:'https://cdn.worldvectorlogo.com/logos/css-3.svg',title:'CSS'},
    {image:'https://cdn.worldvectorlogo.com/logos/material-ui-1.svg',title:'MUI'},
    ]
const HardSkills2 = [
    {image:'https://cdn.worldvectorlogo.com/logos/redux.svg',title:'Redux'},
    {image:'https://cdn.worldvectorlogo.com/logos/bootstrap-4.svg',title:'Bootstrap'},
    {image:'https://cdn.worldvectorlogo.com/logos/framework7.svg',title:'Framework7'},
    {image:'https://cdn.worldvectorlogo.com/logos/jest-2.svg',title:'Jest'},
    {image:'https://www.nuget.org/profiles/MudBlazor/avatar?imageSize=512',title:'Mud Blazor'},
    {image:'https://react-hook-form.com/images/logo/react-hook-form-logo-only.png',title:'React form'},
    ]
const HardSkills3 = [

    {image:'https://cdn.worldvectorlogo.com/logos/java.svg',title:'Java'},
    {image:'https://cdn.worldvectorlogo.com/logos/python-5.svg',title:'Python'},
    {image:'https://cdn.worldvectorlogo.com/logos/c--4.svg',title:'C#'},

    {image:'https://cdn.worldvectorlogo.com/logos/typescript.svg',title:'TypeScript'},
    {image:'https://cdn.worldvectorlogo.com/logos/logo-javascript.svg',title:'JavaScript'},
    {image:'https://cdn.worldvectorlogo.com/logos/postgresql.svg',title:'PostgreSQL'},
    ]
const HardSkills4 = [

    {image:'https://cdn.worldvectorlogo.com/logos/spring-3.svg',title:'Spring Boot'},
    {image:'https://cdn.worldvectorlogo.com/logos/django.svg',title:'Django'},
    {image:'https://cdn.worldvectorlogo.com/logos/blazor.svg',title:'Blazor'},
    {image:'https://www.vectorlogo.zone/logos/expressjs/expressjs-icon.svg',title:'ExpressJS'},
    {image:'https://cdn.worldvectorlogo.com/logos/nodejs-2.svg',title:'NodeJS'},
    {image:'https://play-lh.googleusercontent.com/_ATfgR5IQv2JcYauNzhTgntADBECazjfAkHMmq9xDj2Mcwts18TEJ9m3SYUNtdbsxog=w240-h480-rw',title:'Axios'},
    ]
const HardSkillsSection = () => {
    return (
    <>
        <Hidden lgDown>
            <Grid container item xs={12}  id={'Hard Skills'} >
                <Grid item xs={12} px={'6rem'}>
                    <Typography variant="h2"
                                fontWeight={'bold'}>
                        My Hard Skills
                    </Typography>
                </Grid>
                <Grid item xs={12} ml={20} mt={2} alignSelf={'start'}>
                    <Typography variant="h4"
                                fontWeight={'bold'}>
                        Frontend
                    </Typography>
                </Grid>
                <Grid container item xs={12} direction={'row'} justifyContent={'space-around'} mt={4}>
                    {HardSkills.map((skill) => <SkillCard key={skill.title} title={skill.title}  image={skill.image}/>)}
                </Grid>
                <Grid container item xs={12} direction={'row'} justifyContent={'space-around'} mt={4}>
                    {HardSkills2.map((skill) => <SkillCard key={skill.title} title={skill.title}  image={skill.image}/>)}
                </Grid>
                <Grid item xs={12} ml={20} mt={4} alignSelf={'start'}>
                    <Typography variant="h4"
                                fontWeight={'bold'}>
                        Backend
                    </Typography>
                </Grid>
                <Grid container item xs={12} direction={'row'} justifyContent={'space-around'} mt={4}>
                    {HardSkills3.map((skill) => <SkillCard key={skill.title} title={skill.title}  image={skill.image}/>)}
                </Grid>
                <Grid container item xs={12} direction={'row'} justifyContent={'space-around'} mt={4}>
                    {HardSkills4.map((skill) => <SkillCard key={skill.title} title={skill.title}  image={skill.image}/>)}
                </Grid>
            </Grid>
        </Hidden>
        <Hidden smUp>
            <Grid container item xs={12}  id={'Hard Skills'} >
                <Grid item xs={12} >
                    <Typography variant="h4"
                                fontWeight={'bold'}>
                        My Hard Skills
                    </Typography>
                </Grid>
                <Grid item xs={12} ml={'2rem'} mt={2} alignSelf={'start'}>
                    <Typography variant="h6"
                                fontWeight={'bold'}>
                        Frontend
                    </Typography>
                </Grid>
                <Grid container item xs={12} direction={'row'} justifyContent={'space-between'} mt={4}>
                    {HardSkills.slice(0,3).map((skill) => <Box key={skill.title} display={'flex'} flexDirection={'column'}>
                        <img alt={skill.title} width={'50rem'} height={'50rem'} src={skill.image}/>
                        <Typography variant="caption" textAlign={'center'}>
                            {skill.title}
                        </Typography>
                    </Box>)}
                </Grid>
                <Grid container item xs={12} direction={'row'} justifyContent={'space-between'} mt={4}>
                    {HardSkills.slice(3).map((skill) => <Box key={skill.title} display={'flex'} flexDirection={'column'}  alignItems={'center'}>
                        <img alt={skill.title} width={'50rem'} height={'50rem'} src={skill.image}/>
                        <Typography variant="caption" textAlign={'center'}>
                            {skill.title}
                        </Typography>
                    </Box>)}
                </Grid>
                <Grid container item xs={12} direction={'row'} justifyContent={'space-between'} mt={4}>
                    {HardSkills2.slice(0,3).map((skill) => <Box key={skill.title} display={'flex'} flexDirection={'column'}  alignItems={'center'}>
                        <img alt={skill.title} width={'50rem'} height={'50rem'} src={skill.image}/>
                        <Typography variant="caption" textAlign={'center'}>
                            {skill.title}
                        </Typography>
                    </Box>)}
                </Grid>
                <Grid container item xs={12} direction={'row'} justifyContent={'space-between'} mt={4}>
                    {HardSkills2.slice(3).map((skill) => <Box key={skill.title} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <img alt={skill.title} width={'50rem'} height={'50rem'} src={skill.image}/>
                        <Typography variant="caption" textAlign={'center'}>
                            {skill.title}
                        </Typography>
                    </Box>)}
                </Grid>
                <Grid item xs={12} ml={'2rem'} mt={4} alignSelf={'start'}>
                    <Typography variant="h6"
                                fontWeight={'bold'}>
                        Backend
                    </Typography>
                </Grid>
                <Grid container item xs={12} direction={'row'} justifyContent={'space-between'} mt={4}>
                    {HardSkills3.slice(0,3).map((skill) => <Box key={skill.title} display={'flex'} flexDirection={'column'}  alignItems={'center'}>
                        <img alt={skill.title} width={'50rem'} height={'50rem'} src={skill.image}/>
                        <Typography variant="caption" textAlign={'center'}>
                            {skill.title}
                        </Typography>
                    </Box>)}
                </Grid>
                <Grid container item xs={12} direction={'row'} justifyContent={'space-between'} mt={4}>
                    {HardSkills3.slice(3).map((skill) => <Box key={skill.title} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <img alt={skill.title} width={'50rem'} height={'50rem'} src={skill.image}/>
                        <Typography variant="caption" textAlign={'center'}>
                            {skill.title}
                        </Typography>
                    </Box>)}
                </Grid>
                <Grid container item xs={12} direction={'row'} justifyContent={'space-between'} mt={4}>
                    {HardSkills4.slice(0,3).map((skill) => <Box key={skill.title} display={'flex'} flexDirection={'column'}  alignItems={'center'}>
                        <img alt={skill.title} width={'50rem'} height={'50rem'} src={skill.image}/>
                        <Typography variant="caption" textAlign={'center'}>
                            {skill.title}
                        </Typography>
                    </Box>)}
                </Grid>
                <Grid container item xs={12} direction={'row'} justifyContent={'space-between'} mt={4}>
                    {HardSkills4.slice(3).map((skill) => <Box key={skill.title} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <img alt={skill.title} width={'50rem'} height={'50rem'} src={skill.image}/>
                        <Typography variant="caption" textAlign={'center'}>
                            {skill.title}
                        </Typography>
                    </Box>)}
                </Grid>
            </Grid>
        </Hidden>
        <Hidden smDown lgUp>
            <Grid container item xs={12}  id={'Hard Skills'} >
                <Grid item xs={12} >
                    <Typography variant="h4"
                                fontWeight={'bold'}>
                        My Hard Skills
                    </Typography>
                </Grid>
                <Grid item xs={12} ml={'2rem'} mt={2} alignSelf={'start'}>
                    <Typography variant="h6"
                                fontWeight={'bold'}>
                        Frontend
                    </Typography>
                </Grid>
                <Grid container item xs={12} direction={'row'} justifyContent={'space-between'} mt={4}>
                    {HardSkills.map((skill) => <Box key={skill.title} display={'flex'} flexDirection={'column'}>
                        <img alt={skill.title} width={'50rem'} height={'50rem'} src={skill.image}/>
                        <Typography variant="caption" textAlign={'center'}>
                            {skill.title}
                        </Typography>
                    </Box>)}
                </Grid>
                <Grid container item xs={12} direction={'row'} justifyContent={'space-between'} mt={4}>
                    {HardSkills2.map((skill) => <Box key={skill.title} display={'flex'} flexDirection={'column'}  alignItems={'center'}>
                        <img alt={skill.title} width={'50rem'} height={'50rem'} src={skill.image}/>
                        <Typography variant="caption" textAlign={'center'}>
                            {skill.title}
                        </Typography>
                    </Box>)}
                </Grid>
                <Grid item xs={12} ml={'2rem'} mt={4} alignSelf={'start'}>
                    <Typography variant="h6"
                                fontWeight={'bold'}>
                        Backend
                    </Typography>
                </Grid>
                <Grid container item xs={12} direction={'row'} justifyContent={'space-between'} mt={4}>
                    {HardSkills3.map((skill) => <Box key={skill.title} display={'flex'} flexDirection={'column'}  alignItems={'center'}>
                        <img alt={skill.title} width={'50rem'} height={'50rem'} src={skill.image}/>
                        <Typography variant="caption" textAlign={'center'}>
                            {skill.title}
                        </Typography>
                    </Box>)}
                </Grid>
                <Grid container item xs={12} direction={'row'} justifyContent={'space-between'} mt={4}>
                    {HardSkills4.map((skill) => <Box key={skill.title} display={'flex'} flexDirection={'column'}  alignItems={'center'}>
                        <img alt={skill.title} width={'50rem'} height={'50rem'} src={skill.image}/>
                        <Typography variant="caption" textAlign={'center'}>
                            {skill.title}
                        </Typography>
                    </Box>)}
                </Grid>
            </Grid>
        </Hidden>
    </>
  );
}

export default HardSkillsSection;