import {Collapse, Grid, Hidden, IconButton, LinearProgress, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Languages = [
    {name:'English - Fluent',
        value:80
    },
    {name:'Latvian - Native',
        value:95
    },
    {name:'Russian - Native',
        value:95
    },
]
const SoftSkills = [
    {name:'Communication',
        value:80
    },
    {name:'Adaptability',
        value:95
    },
    {name:'Critical thinking',
        value:90
    },
]
const SoftSkills2 = [
    {name:'Creativity',
        value:75
    },
    {name:'Open-mindedness',
        value:80
    },
    {name:'Initiative',
        value:95
    },
    {name:'Accountability',
        value:90
    },
]
const LanguagesSection = ()=>{
    const theme = useTheme();
    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };
    return(
        <>
            <Hidden lgDown>
                <Grid container item xs={12}  sx={{backgroundColor:theme.palette.secondary.main}} direction={"row"} justifyContent={'space-between'} py={5} px={20} mt={'2rem'} id={'Languages'}>
                    <Grid container item xs={12} sm={6} mt={5} height={'fit-content'} >
                        <Grid item xs={12}>
                            <Typography  sx={{
                                backgroundImage: 'linear-gradient(45deg, purple, orange)',
                                '-webkit-background-clip': 'text',
                                color: 'transparent',
                            }} fontWeight={'bold'} variant={'h2'} color={'primary'}>Languages</Typography>
                        </Grid>
                        {Languages.map(item=>(
                            <Grid container item xs={12} key={item.name}  ml={10} mt={5} direction={'row'} alignItems={'center'}>
                                <Grid item xs={4} >
                                    <Typography fontWeight={'bold'} variant={'h6'} color={'primary'}>{item.name}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <LinearProgress variant="buffer" value={item.value} valueBuffer={100} />
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container item xs={12} sm={6} mt={5}  direction={'column'} height={'fit-content'} >
                        <Grid item xs={12}>
                            <Typography  sx={{
                                backgroundImage: 'linear-gradient(45deg, purple, orange)',
                                '-webkit-background-clip': 'text',
                                color: 'transparent',
                            }} fontWeight={'bold'} variant={'h2'} color={'primary'}>Soft Skills</Typography>
                        </Grid>
                        {SoftSkills.map(item=>(
                            <Grid key={item.name} container item xs={12}  ml={10} mt={5} direction={'row'} alignItems={'center'}>
                                <Grid item xs={4} >
                                    <Typography fontWeight={'bold'} variant={'h6'} color={'primary'}>{item.name}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <LinearProgress variant="buffer" value={item.value} valueBuffer={100} />
                                </Grid>
                            </Grid>
                        ))}
                        <Grid container item xs={12} direction={'column'} justifyContent={'space-between'}>
                            <Collapse  in={checked}>
                                {SoftSkills2.map(item=>(
                                    <Grid key={item.name} container item xs={12}  ml={10} mt={5} direction={'row'} alignItems={'center'}>
                                        <Grid item xs={4}>
                                            <Typography fontWeight={'bold'} variant={'h6'} color={'primary'}>{item.name}</Typography>
                                        </Grid>
                                        <Grid item xs={6} ml={0}>
                                            <LinearProgress variant="buffer" value={item.value} valueBuffer={100} />
                                        </Grid>
                                    </Grid>
                                ))}
                            </Collapse>
                        </Grid>
                        <Grid container item xs={2} direction='row' justifyItems={'center'} alignSelf={'center'} my={4}>
                            <IconButton onClick={handleChange}>{checked?<ExpandLessIcon/>:<ExpandMoreIcon/>}</IconButton>
                        </Grid>
                    </Grid>

                </Grid>
            </Hidden>
            <Hidden lgUp>
                <Grid container item xs={12}  sx={{backgroundColor:theme.palette.secondary.main}} id={'Languages'} mt={'4rem'}>
                    <Grid container item xs={12} mt={'1rem'} mb={'2rem'}>
                        <Grid item xs={12}>
                            <Typography  sx={{
                                backgroundImage: 'linear-gradient(45deg, purple, orange)',
                                '-webkit-background-clip': 'text',
                                color: 'transparent',
                            }} fontWeight={'bold'} textAlign={'center'} variant={'h4'}>Languages</Typography>
                        </Grid>
                        {Languages.map(item=>(
                            <Grid key={item.name} container item xs={12}   mt={'1rem'} direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                <Grid item xs={4} >
                                    <Typography fontWeight={'bold'} variant={'caption'} color={'primary'}>{item.name}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <LinearProgress variant="buffer" value={item.value} valueBuffer={100} />
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>

                    <Grid container item xs={12} >
                        <Grid item xs={12}>
                            <Typography  sx={{
                                backgroundImage: 'linear-gradient(45deg, purple, orange)',
                                '-webkit-background-clip': 'text',
                                color: 'transparent',
                            }} fontWeight={'bold'} variant={'h4'} textAlign={'center'}>Soft Skills</Typography>
                        </Grid>
                        {SoftSkills.map(item=>(
                            <Grid key={item.name} container item xs={12}   mt={'1rem'} direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                <Grid item xs={4} >
                                    <Typography fontWeight={'bold'} variant={'caption'} color={'primary'}>{item.name}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <LinearProgress variant="buffer" value={item.value} valueBuffer={100} />
                                </Grid>
                            </Grid>
                        ))}
                        <Grid container item xs={12} direction={'column'} justifyContent={'space-between'}>
                            <Collapse  in={checked}>
                                {SoftSkills2.map(item=>(
                                    <Grid key={item.name} container item xs={12}   mt={'1rem'} direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                        <Grid item xs={4} >
                                            <Typography fontWeight={'bold'} variant={'caption'} color={'primary'}>{item.name}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <LinearProgress variant="buffer" value={item.value} valueBuffer={100} />
                                        </Grid>
                                    </Grid>
                                ))}
                            </Collapse>
                        </Grid>
                        <Grid item xs={2} mx={'auto'}>
                            <IconButton onClick={handleChange}>{checked?<ExpandLessIcon/>:<ExpandMoreIcon/>}</IconButton>
                        </Grid>
                    </Grid>

                </Grid>
            </Hidden>
        </>
    )
}

export default LanguagesSection