import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Grid, Hidden, Typography} from "@mui/material";
import WorkCard from "../../Molecules/WorkCard/WorkCard";


const experience = [
    {
        company:'Nullcarbon',
        position:'Junior Frontend Developer',
        date:'Feb 2023 - Nov 2023',
        description:['Developed a clear screen UI for building’s environmental impact reporting platform.','Enhanced real-time data integration in dynamic tables.','Created centralized state management for convenient flow of the application.']
    },
    {
        company:'PrimeIT',
        position:'Full Stack Developer',
        date:'May 2023 - present',
        description:['Developing predominantly Warehouse Management Software.',
            'Utilizing C#, Blazor, MudBlazor library and Bootstrap for robust solutions.',
            'Enhance software in my spare time, demonstrating a proactive approach to learning and development.']
    },
    {
        company:'Kamstrup',
        position:'Frontend Developer ',
        date:'September 2022 - June 2023',
        description:['Implemented UI for Dashboard joining crucial information about power using TypeScript and MUI.',
            'Designed and created custom UI tiles for data presentation in the application.',
            'Successfully defended Bachelor thesis based on this project.']
    },
    {
        company:'RaskRask',
        position:'Full-Stack Intern Developer',
        date:'January 2022 - Aug 2022',
        description:['Created a web app for support team, simplifying access to customer balance and transactions.',
            'Used React and TypeScript with Atomic design to enhance frontend stability and scalability.',
            'Integrated controllers and data management tools into RaskRaskApi, improving system efficiency.']
    },
    {
        company:'Coinfide LTD',
        position:'QA and Software Tester ',
        date:'June 2015 - Aug 2015  and  June 2016 - Aug 2016 ',
        description:['Spotted and recorded visual errors in an e-wallet app during a summer job.',
            'Tested new features performance thoroughly.',
            'Reported findings clearly, aiding app improvements.']
    },
]
 function WorkExperience() {

    return (
            <>
                <Hidden lgDown>
                    <Grid container item xs={12} mb={6} id={'Work Experience'} my={8}>
                        <Grid container item xs={12} mt={2} mb={2} direction={'row'} justifyContent={'center'} >
                            <Grid item  >
                                <Typography
                                    sx={{
                                        backgroundImage: 'linear-gradient(45deg, purple, orange)',
                                        '-webkit-background-clip': 'text',
                                        color: 'transparent',
                                        display: 'inline',
                                    }}
                                    variant="h2"
                                    fontWeight={'bold'}

                                >
                                    Work Experience</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Timeline position="alternate">
                                {experience.map((item,index) => (
                                    <TimelineItem key={index + item.company}>
                                        <TimelineSeparator color={'primary'}>
                                            <TimelineDot  color={'primary'} />
                                            <TimelineConnector color={'primary'} />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Typography variant={'h5'} fontWeight={700} mb={1}>{item.company}</Typography>
                                            <Typography variant={'h6'} mb={1}>{item.position}</Typography>
                                            <Typography variant={'overline'} mb={1}>{item.date}</Typography>
                                            {item.description.map((item,index) => (
                                                <Typography mb={1} key={index + item}>{item}</Typography>
                                            ))}
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}
                            </Timeline>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden lgUp>
                    <Grid container item xs={12} mb={6} id={'Work Experience'}>
                        <Grid container item xs={12} mt={2} mb={2} direction={'row'} justifyContent={'center'} >
                            <Grid item  >
                                <Typography
                                    sx={{
                                        backgroundImage: 'linear-gradient(45deg, purple, orange)',
                                        '-webkit-background-clip': 'text',
                                        color: 'transparent',
                                        display: 'inline',
                                    }}
                                    variant="h4"
                                    fontWeight={'bold'}

                                >
                                    Work Experience</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>

                                {experience.map((item) => (
                                   <WorkCard key={item.position + item.company} {...item}/>
                                ))}

                        </Grid>
                    </Grid>
                </Hidden>
            </>
    );
}
export default WorkExperience;