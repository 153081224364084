import {
    AppBar,
    Button, Grid, Hidden, IconButton, Tab, Tabs, Toolbar, Typography
} from "@mui/material";
import React from "react";
import MenuIcon from '@mui/icons-material/Menu';
import useScrollTo from "../../../Hooks/useScollToElement/useScrollToElement";
interface AppBarProps {
    handleTheme: () => void
    themes: 'dark'|'light'
    handleOpenModal:()=>void
}

const AppBarRender = ({handleTheme,themes,handleOpenModal}:AppBarProps) => {
    const scrollTo = useScrollTo()
    const [value, setValue] = React.useState(0);


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return(
                         <Hidden lgDown >
                             <Grid container direction={'row'} columns={14} xs={14} item my={'2rem'} px={'4rem'} >
                             {/*<Grid container item sm={10} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>*/}
                                 <Grid item sm={2}>
                                     <Typography variant={'h4'}>Romans</Typography>
                                 </Grid>
                                 <Grid item sm={8} container direction={ 'row'} justifyContent={'center'}>
                                     <Tabs sx={{width:'fit-content'}} value={value} onChange={handleChange} aria-label="basic tabs example">
                                         <Tab onClick={()=>{scrollTo('Profile')}} href={'#Profile'} label="Profile" />
                                         <Tab onClick={()=>{scrollTo('Work Experience')}} href={'#Work Experience'} label="Work Experience" />
                                         <Tab onClick={()=>{scrollTo('Education')}} href={'#Education'} label="Education"/>
                                         <Tab onClick={()=>{scrollTo('Hard Skills')}} href={'#Hard Skills'} label="Hard Skills"/>
                                         <Tab onClick={()=>{scrollTo('Languages')}} href={'#Languages'} label="Languages"/>
                                         <Tab onClick={()=>{scrollTo('Contact')}} href={'#Contact'} label="Contact"/>
                                     </Tabs>
                                 </Grid>
                             {/*</Grid>*/}
                             <Grid container item sm={2} alignItems={'center'}>
                                 <Grid item sm={8} >
                                     <Button onClick={handleOpenModal} variant={'contained'} sx={{borderRadius:'20px'}}  color={"primary"}>Let's talk</Button>
                                 </Grid>
                                 <Grid item sm={2}>
                                     <Button onClick={handleTheme} variant={'contained'} sx={{borderRadius:'20px'}}  color={"primary"}>{themes}</Button>
                                 </Grid>
                             </Grid>
                             </Grid>
                         </Hidden>
    )
}
export default AppBarRender






