import {Button, Divider, Grid, IconButton, Typography} from "@mui/material";
import React from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
interface EducationCardProps{
    education: {
        place: string,
        degree: string,
        date: string
        link: string
    },
    type:'lg'|'sm'
}
const OtherCard = ({ education:{place,degree ,date, link}, type  }: EducationCardProps) => {
    return(
        <>
            {type === 'sm' ? (
                    <Grid container item xs={12} direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={4}>
                        <Grid container item xs={8} >
                            <Grid item xs={12} >
                                <Typography fontWeight={600} variant={'subtitle1'}>{place}</Typography>
                            </Grid>
                            <Grid item xs={12}> <Typography variant={'caption'}> {degree}</Typography></Grid>
                        </Grid>
                        <Grid container item xs={3} direction={'row'} alignItems={'center'} justifyContent={'space-around'}>
                            <Grid item xs={3}>
                                <Typography variant={'caption'}>{date}</Typography>
                            </Grid>
                            <Grid item xs={2}> <IconButton href={link} target={'_blank'}><ChevronRightIcon/> </IconButton></Grid>
                        </Grid>

                        <Grid item xs={12} mt={'1rem'}> <Divider /></Grid>
                    </Grid>
                ):
                (
                    <Grid container item xs={12} direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={4}>
                        <Grid container item xs={8}>
                            <Grid item xs={12} >
                                <Typography variant={'h6'}>{place}</Typography>
                            </Grid>
                            <Grid item xs={12}> <Typography variant={'body1'}> {degree}</Typography></Grid>
                        </Grid>

                        <Grid container item xs={3} direction={'row'} alignItems={'center'}>
                            <Grid item xs={6}> <Typography variant={'subtitle2'}>{date}</Typography>
                            </Grid>
                            <Grid item xs={6}> <Button variant={'contained'} color={'primary'} href={link} target={'_blank'}>More </Button></Grid>
                        </Grid>
                        <Grid item xs={12} mt={4} > <Divider /></Grid>
                    </Grid>
                )
            }
        </>
    )
}
export default OtherCard;