import {Grid, Hidden} from "@mui/material";
import ProfileSection from "../../components/Organs/ProfileSection/ProfileSection";
import LineSpacer from "../../components/Organs/LineSpacer/LineSpacer";
import React from "react";
import WorkExperience from "../../components/Organs/WorkExperience/WorkExperience";
import EducationSection from "../../components/Organs/Education/EducationSection";
import HardSkillsSection from "../../components/Organs/HardSkillsSection/HardSkillsSection";
import FloatingSideMenu from "../../components/Organs/FloatingSideMenu/FloatingSideMenu";
import LanguagesSection from "../../components/Organs/LanguagesSection/LanguagesSection";
import ScrollToTopButton from "../../components/Atoms/ScrollToTopButton/ScrollToTopButton";
import ContactSection from "../../components/Organs/ContactSection/ContactSection";
import FooterSection from "../../components/Organs/FooterSection/FooterSection";
interface HomeProps {
handleClick:()=>void
    openSnack:boolean
    handleClose: (event: React.SyntheticEvent | Event, reason?: string)=>void
    handleOpenModal:()=>void
}
const Home = ({handleClick, handleClose, openSnack,handleOpenModal}:HomeProps) => {

    return(
       <>
           <Grid container px={'2rem'} mt={'6rem'}>
               <ProfileSection handleOpenModal={handleOpenModal}/>
           </Grid>
           <LineSpacer />
           <Grid container px={'2rem'} >
               <WorkExperience/>
               <EducationSection/>
               <HardSkillsSection/>
               <LanguagesSection />
               <ContactSection handleClick={handleClick} openSnack={openSnack} handleClose={handleClose}/>
               <FooterSection />
               <Hidden lgDown>
                   <FloatingSideMenu/>
               </Hidden>
               <ScrollToTopButton />
           </Grid>
       </>
    )
}
export default Home;