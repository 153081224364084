import {
    Button,
    Card,
    CardActions,
    CardContent,
    Fade,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import React from "react";
interface WorkCardProps{
    company:string,
    position:string,
    date:string,
    description:string[]
}
const WorkCard = ({ description,position,date,company }: WorkCardProps) => {
    const [open, setOpen] = React.useState(false);
    const toggleMore = () => {
        setOpen(!open)
    }
    return(
        <Card sx={{ minWidth: 275, marginBottom:'1rem' }}  >
            <CardContent>
                <Typography variant={'h5'} fontWeight={700} >{company}</Typography>
                <Typography variant={'h6'} >{position}</Typography>
                <Typography variant={'overline'}>{date}</Typography>
                <Fade in={open}>
                    <List sx={{width:'100%'}}>
                        {open && description.map((item,index) => (
                            <ListItem disablePadding key={index + item} >
                                <ListItemIcon sx={{minWidth:'fit-content', marginRight:'1rem'}}><CircleIcon sx={{fontSize:'12px'}}/></ListItemIcon>
                                <ListItemText sx={{padding:0}}>

                                    <Typography variant={'caption'}>
                                    {item}
                                </Typography>
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Fade>
            </CardContent>
            <CardActions>
                <Button onClick={toggleMore} size="small">{open ? 'Less': 'Learn More'}</Button>
            </CardActions>
        </Card>
    )
}
export default WorkCard;