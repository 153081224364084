import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Grid, useTheme} from "@mui/material";

interface SkillCardProps {
    title:string,
    image:string,
}
export default function SkillCard({title,image}:SkillCardProps) {
    const theme = useTheme()
    return (
        <Grid container item xs={1} sx={{backgroundColor:theme.palette.secondary.main, boxShadow:'0 0 10px 0 rgba(0,0,0,0.1)'}} >
            <Grid item  xs={12} px={5} pt={1} mt={3} mb={1}>
                <img
                    style={{width:'100%'}}
                    alt={title}
                    src={image}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography gutterBottom variant="body1" color={"primary"} fontWeight={700} textAlign={'center'}>
                    {title}
                </Typography>
            </Grid>
        </Grid>
    );
}