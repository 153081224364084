import {Divider, Grid, Hidden, IconButton, ListItem, Typography, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import BoltIcon from "@mui/icons-material/Bolt";
import CopyrightIcon from '@mui/icons-material/Copyright';
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
const FooterSection = ()=>{
    const [activeSection, setActiveSection] = useState(null);
    function scrollToSection(sectionId:string) {
        const element = document.getElementById(sectionId);
        if (element) {
            const yOffset = -100; // Adjust this value as needed for the offset
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            let currentSection = null;

            const sections = ['Profile', 'Work Experience', 'Education', 'Hard Skills', 'Languages', 'Links', 'Contact'];

            sections.forEach(section => {
                const element = document.getElementById(section);
                const rect = element && element.getBoundingClientRect();
                if (rect && rect.top >= 0 && rect.top <= window.innerHeight / 2) { // You can adjust this to better suit your needs
                    currentSection = section;
                }
            });

            setActiveSection(currentSection);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    const theme = useTheme()
    return(
        <>
            <Hidden lgDown>
                <Grid container item xs={12} my={'4rem'} px={'10rem'} >
                    <Grid item xs={12} alignSelf={'center'}  my={'2rem'}>
                        <Divider />
                    </Grid>
                    <Grid container item sm={12} direction={'row'} justifyContent={'space-between'}>
                        <Grid item sm={6}>
                            <Typography variant={'h4'}>Romans Jefremovs</Typography>
                        </Grid>
                        <Grid container  direction={'row'} width={'fit-content'}>

                            {/*<List>*/}
                            {['Profile', 'Work Experience', 'Education', 'Hard Skills', 'Languages', 'Contact'].map(section => (
                                <Box  key={ section} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    {activeSection === section ? <BoltIcon /> : undefined}
                                    <ListItem onClick={()=>scrollToSection(section)} key={section} sx={activeSection === section ? { borderBottom: `1px solid ${theme.palette.primary.main}` } : {marginLeft:3,borderBottom: '1px solid rgba(0,0,0,0)', '&:hover': {
                                            borderBottom: `1px solid ${theme.palette.primary.main}`
                                        }}}>
                                        {section}
                                    </ListItem>
                                </Box>
                            ))}
                            {/*</List>*/}

                        </Grid>
                    </Grid>
                    <Grid container item sm={12} direction={'row'} mt={'4rem'} justifyContent={'space-between'}>
                        <Grid container item sm={4} direction={'row'} alignItems={'center'}>
                            <CopyrightIcon fontSize={'small'} />
                            <Typography marginLeft={'0.5rem'} variant={'caption'}> Implemented By Romans Jefremovs</Typography>

                        </Grid>
                        <Grid container item direction={'row'} width={'fit-content'}>  <IconButton href={'https://www.linkedin.com/in/romans-jefremovs/'} target={'_blank'}>
                            <LinkedInIcon   fontSize={'large'}/>
                        </IconButton>
                            <IconButton href={'https://github.com/RomansJefremovs'} target={'_blank'}>
                                <GitHubIcon sx={{marginLeft:'1rem'}}   fontSize={'large'}/>
                            </IconButton></Grid>
                    </Grid>
                </Grid>
            </Hidden>
            <Hidden lgUp>
                <Grid container item xs={12} mb={'2rem'} >
                    <Grid item xs={12} alignSelf={'center'}  my={'2rem'}>
                        <Divider />
                    </Grid>
                    <Grid container item xs={12} direction={'row'} justifyContent={'space-between'}>
                        <Grid item xs={8}>
                            <Typography variant={'h6'}>Romans Jefremovs</Typography>
                        </Grid>
                        <Grid container item direction={'row'} width={'fit-content'}>
                            <IconButton href={'https://www.linkedin.com/in/romans-jefremovs/'} target={'_blank'}>
                                <LinkedInIcon   fontSize={'large'}/>
                            </IconButton>
                            <IconButton href={'https://github.com/RomansJefremovs'} target={'_blank'}>
                                <GitHubIcon sx={{marginLeft:'1rem'}}   fontSize={'large'}/>
                            </IconButton>
                        </Grid>
                        <Grid container item xs={12} direction={'row'} mt={'2rem'}>
                            {['Profile', 'Work Experience', 'Education', 'Hard Skills', 'Languages', 'Contact'].slice(0,3).map(section => (
                                <Box key={section} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    {activeSection === section ? <BoltIcon /> : undefined}
                                    <ListItem disablePadding onClick={()=>scrollToSection(section)} key={section} sx={activeSection === section ? { borderBottom: `1px solid ${theme.palette.primary.main}` } : {marginLeft:3,borderBottom: '1px solid rgba(0,0,0,0)', '&:hover': {
                                            borderBottom: `1px solid ${theme.palette.primary.main}`
                                        }}}>
                                        <Typography variant={'caption'}>{section}</Typography>
                                    </ListItem>
                                </Box>
                            ))}
                        </Grid>
                        <Grid container item xs={12} direction={'row'} mt={'1rem'}>
                            {['Profile', 'Work Experience', 'Education', 'Hard Skills', 'Languages', 'Contact'].slice(3).map(section => (
                                <Box key={section} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    {activeSection === section ? <BoltIcon /> : undefined}
                                    <ListItem disablePadding onClick={()=>scrollToSection(section)} key={section} sx={activeSection === section ? { borderBottom: `1px solid ${theme.palette.primary.main}` } : {marginLeft:3,borderBottom: '1px solid rgba(0,0,0,0)', '&:hover': {
                                            borderBottom: `1px solid ${theme.palette.primary.main}`
                                        }}}>
                                        <Typography variant={'caption'}>{section}</Typography>
                                    </ListItem>
                                </Box>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid container item sm={12} direction={'row'} mt={'4rem'} justifyContent={'space-between'}>
                        <Grid container item sm={4} direction={'row'} alignItems={'center'}>
                            <CopyrightIcon fontSize={'small'} />
                            <Typography marginLeft={'0.5rem'} variant={'caption'}> Implemented By Romans Jefremovs</Typography>

                        </Grid>
                    </Grid>
                </Grid>
            </Hidden>
        </>
    )
}
export default FooterSection