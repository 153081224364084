import WebFont from 'webfontloader';
import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from "./reportWebVitals";

WebFont.load({
    google: {
        families: ['Bai Jamjuree:400,700', 'sans-serif'],
    },
});


ReactDOM.render(
        <App />,
    document.querySelector('#root'),
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
