import GradientLineSpacer from "./GradientLineSpacer";
import RotatedLineSpacer from "./RotatedLineSpacer";
import './profile.css'
const LineSpacer= () => {
    return(
        <div className={'lineSpacer'} style={{marginTop:'4rem'}}>
            <GradientLineSpacer/>
            <RotatedLineSpacer />
        </div>
    )
}
export default LineSpacer;