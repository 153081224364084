import { createTheme } from '@mui/material/styles';

// Light theme
export const lightTheme = createTheme({
    palette: {
        mode: 'light', // you can simply change this to 'dark' for dark mode
        background: {
            default: '#F0F2F5',
            paper: '#FFFFFF',
        },
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#FFFFFF',
        }
        // other palette settings
    },
    typography: {
        fontFamily: '"Bai Jamjuree", sans-serif',
    },
    // ...other settings,
});

// Dark theme
export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        // other palette settings
        primary: {
            main: '#FFFFFF',
        },
        secondary: {
            main: '#1C1C22',
        }
    },

    typography: {
        fontFamily: '"Bai Jamjuree", sans-serif',
    },
    // ...other settings,
});
