import {Divider, Grid, Typography} from "@mui/material";
import React from "react";
interface EducationCardProps{
   education: {
       place: string,
       degree: string,
       date: string,
       link: string
   },
    type:'lg'|'sm'
}
const EducationCard = ({ education:{place,degree ,date}, type  }: EducationCardProps) => {
    return(
       <>
           {type === 'sm' ? (
               <Grid container item xs={12} direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={4}>
                   <Grid container item xs={8} >
                       <Grid item xs={12} >
                           <Typography fontWeight={600} variant={'subtitle1'}>{place}</Typography>
                       </Grid>
                       <Grid item xs={12}> <Typography variant={'caption'}> {degree}</Typography></Grid>
                   </Grid>
                   <Grid item xs={3}>
                       <Typography variant={'caption'}>{date}</Typography>
                   </Grid>
                   <Grid item xs={12} mt={'1rem'}> <Divider /></Grid>
               </Grid>
           ):
               (
                   <Grid container item xs={12} direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={4}>
                       <Grid container item xs={8} mb={4} >
                           <Grid item xs={12} >
                               <Typography variant={'h6'}>{place}</Typography>
                           </Grid>
                           <Grid item xs={12}> <Typography variant={'body1'}>{degree}</Typography></Grid>
                       </Grid>
                       <Grid item xs={2}>
                           <Typography variant={'subtitle2'}>{date}</Typography>
                       </Grid>
                       <Grid item xs={12}> <Divider /></Grid>
                   </Grid>
               )
           }
       </>
    )
}
export default EducationCard;