import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {darkTheme, lightTheme} from "./components/theme/theme";
import Home from "./pages/Home/Home";
import AppBarRender from "./components/Organs/AppBar/AppBar";
import MenuDrawer from "./components/Organs/MenuDrawer/MenuDrawer";
import AppBarMob from "./components/Organs/AppBar/AppBarMob";
import ContactModal from "./components/Molecules/ContactModal"; // import your themes from where they are defined

const App: React.FC = () => {
  // State to hold the selected theme
  const [darkMode, setDarkMode] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [openSnack, seSnacktOpen] = React.useState(false);

    const handleClick = () => {
        seSnacktOpen(true);
    };

    const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        seSnacktOpen(false);
    };

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    }

    const handleDrawerClose = () => {
        setOpen(false);
    }
  // Function to toggle between light and dark theme
  const toggleDarkMode = () => setDarkMode(!darkMode);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        {/*<Grid container >*/}
        <AppBarRender handleTheme={toggleDarkMode} themes={darkMode? 'light':'dark'}  handleOpenModal={handleOpen}/>
        <AppBarMob  handleDrawerOpen={handleDrawerOpen}/>
        <MenuDrawer open={open} handleTheme={toggleDarkMode} themes={darkMode? 'light':'dark'}  handleDrawerClose={handleDrawerClose}/>
            <Home handleOpenModal={handleOpen} openSnack={openSnack} handleClose={handleCloseSnack} handleClick={handleClick}/>
        <ContactModal handleClick={handleClick} open={openModal} handleClose={handleClose}/>
        {/*</Grid>*/}

      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      {/* Your content here, pass the toggleDarkMode to a button or switch to change themes */}
    </ThemeProvider>
  );
};

export default App;
