import {Button, Grid, IconButton, Snackbar, TextField, Typography, useTheme} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {useForm} from "react-hook-form";

interface FormData {
    name: string;
    email: string;
    message: string;

}
interface ContactMeFormProps{
    sent?:()=>boolean
    handleClick:()=>void
}
const ContactMeForm = ({sent,handleClick}:ContactMeFormProps)=>{
    const theme = useTheme();
    const { register, handleSubmit, formState: { errors },reset } = useForm<FormData>();

    const onSubmit = async (data:FormData) => {
        try {
            const response = await fetch("https://formspree.io/f/mgejdpwq", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                handleClick()
                reset()
                sent && sent()
                // console.log("Form successfully sent");
                // Handle success scenario
            } else {
                console.error("Formspree submission error:", response.statusText);
            }
        } catch (error) {
            console.error("Error submitting the form:", error);
        }
    };
    return(
        <>
            <Grid
                component={'form'}
                onSubmit={handleSubmit(onSubmit)}
                container
                xs={12}
                mt={'2rem'}
            >
                <Grid item xs={12} mb={'1rem'}>
                    <Typography color={'primary'} variant="body1" fontWeight={600} width={'fit-content'}>Your name</Typography>
                    <TextField
                        {...register("name", { required: "Name is required" })}
                        type={'text'}
                        fullWidth
                        variant="outlined"
                        sx={{backgroundColor: theme.palette.secondary.main}}
                        error={errors.name ? true : false}
                        // helperText={<>{errors.name && errors.name.message}</>}
                    />
                    {errors.name && <Typography>{errors.name.message}</Typography>}
                </Grid>
                <Grid item xs={12} mb={'1rem'}>
                    <Typography color={'primary'} variant="body1" fontWeight={600} width={'fit-content'}>Your E-mail</Typography>
                    <TextField
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email address"
                            }
                        })}
                        type={'email'}
                        fullWidth
                        variant="outlined"
                        sx={{backgroundColor: theme.palette.secondary.main, }}
                        error={errors.email ? true : false}
                    />
                    {errors.email && <Typography>{errors.email.message}</Typography>}
                </Grid>
                <Grid item xs={12} mb={'1rem'}>
                    <Typography color={'primary'} variant="body1" fontWeight={600} width={'fit-content'}>Tell me about what you are looking for?</Typography>
                    <TextField
                        {...register("message", { required: "Message is required" })}
                        fullWidth
                        multiline
                        minRows={8}
                        variant={'outlined'}
                        sx={{backgroundColor: theme.palette.secondary.main,  '& .MuiFormHelperText-root': {
                                backgroundColor: 'transparent'
                            }}}
                        error={errors.message ? true : false}
                        // helperText={<>{errors.message && errors.message.message}</>}

                    />
                    {errors.message && <Typography>{errors.message.message}</Typography>}
                </Grid>
                <Grid item xs={12} mb={'2rem'}>
                    <Button size={'large'} variant={'contained'} type={'submit'} color={'primary'} sx={{ borderRadius:'20px', backgroundImage: 'linear-gradient(90deg, #b16cea, #ffa14e)', display: 'inline' }}>
                        <Typography color={"primary"} variant={'h6'}>GET IN TOUCH</Typography>
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default ContactMeForm;