import {Button, Grid, Hidden, IconButton, Snackbar, TextField, Typography, useTheme} from "@mui/material";
import * as React from "react";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import {useForm} from "react-hook-form";
import ContactMeForm from "../../Molecules/ContactMeForm/ContactMeForm";
interface FormData {
    name: string;
    email: string;
    message: string;
}
interface  ContactSectionProps{
    openSnack:boolean
    handleClose: (event: React.SyntheticEvent | Event, reason?: string)=>void
    handleClick:()=>void
}
const ContactSection = ({openSnack, handleClose,handleClick}:ContactSectionProps) => {
    const theme = useTheme();
    const { register, handleSubmit, formState: { errors },reset } = useForm<FormData>();

    const onSubmit = async (data:FormData) => {
        try {
            const response = await fetch("https://formspree.io/f/mgejdpwq", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                handleClick()
                reset()
                // console.log("Form successfully sent");
                // Handle success scenario
            } else {
                console.error("Formspree submission error:", response.statusText);
            }
        } catch (error) {
            console.error("Error submitting the form:", error);
        }
    };


    return (
      <>
          <Hidden lgDown>
              <Grid container item xs={12} id={'Contact'} mt={'4rem'}>
                  <Grid container item xs={12} sm={6} direction={'row'} justifyContent={'center'}>
                      <Grid item xs={8}>
                          <Typography
                              sx={{
                                  backgroundImage: 'linear-gradient(45deg, purple, orange)',
                                  '-webkit-background-clip': 'text',
                                  color: 'transparent',
                                  display: 'inline',
                              }}
                              variant="h2"
                              fontWeight={'bold'}

                          >
                              Get in touch</Typography>
                      </Grid>
                      <Grid item xs={8} mt={4}>
                          <Typography
                              variant="h6"
                          >
                              If you have a project in mind, drop a message for me using the form. I will Get back to you as soon as possible and we can organize a meeting where we discuss requirements and best deal.</Typography>
                      </Grid>
                      <Grid container item xs={8} mt={'10rem'} direction={'row'} alignItems={'center'}>
                          <LocalPhoneIcon fontSize={'large'} />
                          <Typography
                              marginLeft={'1rem'}
                              variant="h6"
                              width={'fit-content'}
                          >
                              +45 20 84 80 64
                          </Typography>
                      </Grid>
                      <Grid container item xs={8} mt={4} direction={'row'} alignItems={'center'}>
                          <EmailIcon fontSize={'large'} />
                          <Typography
                              marginLeft={'1rem'}
                              variant="h6"
                              width={'fit-content'}
                          >
                              romans.jefremov@gmail.com
                          </Typography>
                      </Grid>
                      <Grid item xs={8} mt={4}>  <IconButton href={'https://www.linkedin.com/in/romans-jefremovs/'} target={'_blank'}>
                          <LinkedInIcon   fontSize={'large'}/>
                      </IconButton>
                          <IconButton href={'https://github.com/RomansJefremovs'} target={'_blank'}>
                              <GitHubIcon sx={{marginLeft:'1rem'}}   fontSize={'large'}/>
                          </IconButton></Grid>
                  </Grid>

                  <Grid
                      component={'form'}
                      onSubmit={handleSubmit(onSubmit)}
                      container
                      item
                      xs={12} sm={6}
                      direction={'row'}
                      justifyContent={'center'}
                      height={'fit-content'}
                  >
                      {/* Name Field */}
                      <Grid item xs={8} mb={'2rem'}>
                          <Typography color={'primary'} variant="h6" fontWeight={600} width={'fit-content'}>Your name</Typography>
                          <TextField
                              {...register("name", { required: "Name is required" })}
                              type={'text'}
                              fullWidth
                              variant="outlined"
                              sx={{backgroundColor: theme.palette.secondary.main}}
                              error={errors.name ? true : false}
                              // helperText={<>{errors.name && errors.name.message}</>}
                          />
                          {errors.name && <Typography>{errors.name.message}</Typography>}
                      </Grid>

                      {/* Email Field */}
                      <Grid item xs={8} mb={'2rem'}>
                          <Typography color={'primary'} variant="h6" fontWeight={600} width={'fit-content'}>Your E-mail</Typography>
                          <TextField
                              {...register("email", {
                                  required: "Email is required",
                                  pattern: {
                                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                      message: "Invalid email address"
                                  }
                              })}
                              type={'email'}
                              fullWidth
                              variant="outlined"
                              sx={{backgroundColor: theme.palette.secondary.main, }}
                              error={errors.email ? true : false}
                          />
                          {errors.email && <Typography>{errors.email.message}</Typography>}
                      </Grid>

                      {/* Message Field */}
                      <Grid item xs={8} mb={'2rem'}>
                          <Typography color={'primary'} variant="h6" fontWeight={600} width={'fit-content'}>Tell me about what you are looking for?</Typography>
                          <TextField
                              {...register("message", { required: "Message is required" })}
                              fullWidth
                              multiline
                              minRows={8}
                              variant={'outlined'}
                              sx={{backgroundColor: theme.palette.secondary.main,  '& .MuiFormHelperText-root': {
                                      backgroundColor: 'transparent'
                                  }}}
                              error={errors.message ? true : false}
                              // helperText={<>{errors.message && errors.message.message}</>}

                          />
                          {errors.message && <Typography>{errors.message.message}</Typography>}
                      </Grid>

                      {/* Submit Button */}
                      <Grid item xs={8} mb={'2rem'}>
                          <Button size={'large'} variant={'contained'} type={'submit'} color={'primary'} sx={{ borderRadius:'20px', backgroundImage: 'linear-gradient(90deg, #b16cea, #ffa14e)', display: 'inline' }}>
                              <Typography color={"primary"} variant={'h6'}>GET IN TOUCH</Typography>
                          </Button>
                      </Grid>
                  </Grid>
                  <Snackbar
                      open={openSnack}
                      autoHideDuration={6000}
                      onClose={handleClose}
                      message="Note archived"
                      action={ <IconButton
                          size="small"
                          aria-label="close"
                          color="inherit"
                          onClick={handleClose}
                      >
                          <CloseIcon fontSize="small" />
                      </IconButton>}
                  />
              </Grid>
          </Hidden>
          <Hidden lgUp>
              <Grid container item xs={12} mt={'4rem'} id={'Contact'}>
                  <Grid container item xs={12} direction={'row'} justifyContent={'center'}>
                      <Grid item xs={12}>
                          <Typography
                              sx={{
                                  backgroundImage: 'linear-gradient(45deg, purple, orange)',
                                  '-webkit-background-clip': 'text',
                                  color: 'transparent',
                                  display: 'inline',
                              }}
                              variant="h4"
                              fontWeight={'bold'}

                          >
                              Get in touch</Typography>
                      </Grid>
                      <Grid item xs={12} mt={2}>
                          <Typography
                              variant="body1"
                          >
                              If you have a project in mind, drop a message for me using the form. I will Get back to you as soon as possible and we can organize a meeting where we discuss requirements and best deal.</Typography>
                      </Grid>
                      <Grid container item xs={12} mt={'1rem'} direction={'row'} alignItems={'center'}>
                          <LocalPhoneIcon fontSize={'small'} />
                          <Typography
                              marginLeft={'1rem'}
                              variant="body1"
                              width={'fit-content'}
                          >
                              +45 20 84 80 64
                          </Typography>
                      </Grid>
                      <Grid container item xs={12} mt={'1rem'} direction={'row'} alignItems={'center'}>
                          <EmailIcon fontSize={'small'} />
                          <Typography
                              marginLeft={'1rem'}
                              variant="body1"
                              width={'fit-content'}
                          >
                              romans.jefremov@gmail.com
                          </Typography>
                      </Grid>
                  </Grid>
                  <ContactMeForm handleClick={handleClick} />
                  <Snackbar
                      open={openSnack}
                      autoHideDuration={2000}
                      onClose={handleClose}
                      message="Message sent! Thank you for contacting me."
                      action={ <IconButton
                          size="small"
                          aria-label="close"
                          color="inherit"
                          onClick={handleClose}
                      >
                          <CloseIcon fontSize="small" />
                      </IconButton>}
                  />
              </Grid>
          </Hidden>
      </>
    )
}
export default ContactSection