import React, { useState, useEffect } from 'react';
import {IconButton} from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
function ScrollToTopButton() {
    const [isVisible, setIsVisible] = useState(false);

    // Show button when page is scrolled up to given distance
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    // Scroll to top smoothly
    function smoothScrollToTop(duration:number) {
        const startingY = window.pageYOffset;
        const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

        // Take into account if there's a difference in scroll position between the start and the document's top
        const deltaY = -startingY;

        const easeInOutQuart = (time:number, from:number, delta:number, duration:number) => {
            if ((time /= duration / 2) < 1) return delta / 2 * time * time * time * time + from;
            return -delta / 2 * ((time -= 2) * time * time * time - 2) + from;
        };

        const animation = () => {
            const now = 'now' in window.performance ? performance.now() : new Date().getTime();
            const time = Math.min(1, ((now - startTime) / duration));
            const timeFunction = easeInOutQuart(time, startingY, deltaY, 1);
            window.scroll(0, timeFunction);

            if (window.pageYOffset === 0) return;

            requestAnimationFrame(animation);
        };

        animation();
    }


    return (
        <div>
            {isVisible &&
                <IconButton onClick={()=>smoothScrollToTop(1000)} style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '30px',
                    padding: '10px 20px',
                    backgroundColor: '#000',
                    color: '#FFF',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    zIndex: 1000
                }} ><ArrowUpwardIcon /></IconButton>}
        </div>
    );
}

// Styles for the button
// const styles = {
//     // To ensure button remains on top of other content
// };

export default ScrollToTopButton;
